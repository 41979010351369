$color-top:#ededed;
$color-row:#ededed;
$border-color:#ccc;

.groups {
    display:flex;
    background-color:#fff;
    font-size:16px;
    width:100%;
    position: relative;

    /*.shadow {
        visibility:hidden;
        opacity:0;
        height:30px;
        position: absolute;
        width:25px;
        height:100%;
    }

    .shadow-left {
        border-radius:2px 0px 0px 2px;
        left:0;
        background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.2));
    }

    .shadow-right {
        border-radius:0px 2px 2px 0px;
        right:0;
        background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.2));
    }*/

    ul {
        margin:0;
        padding:0;
        border-radius:2px;
        border:1px solid $border-color;
        overflow-x:auto;
        width:100%;
    }

    li {
        margin:0;
        list-style-type: none;
        display:flex;
        align-items: center;
        min-width:fit-content;

        &:nth-child(2n + 3){
            background-color:$color-row;
        }

        & > div {
            padding:10px;
        }

        .pos {
            min-width:60px;
            font-style: italic;
            text-align:center;
            color: rgba(0,0,0,0.75);
            font-size: 10pt;
        }

        .text {
            min-width:200px;
            width:100%;
        }

        .res {
            min-width:60px;
            text-align:right;
            text-align:center;

            &:nth-last-child(1){
                font-weight:bold;
            }
        }
    }

    .group_head {
        background-color:$color-top;
        border-bottom:1px solid $border-color;
    }
}
