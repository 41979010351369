#verify {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: renderVerify 0.5s ease;
  -webkit-animation: renderVerify 0.5s ease;

  @keyframes renderVerify {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  @-webkit-keyframes renderVerify {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }

  .container {
      min-width: 500px;
      min-height: 380px;
      margin:20px;
      max-width: 500px;
      transition: all 0.3s ease;
  }

  .logo {
    background-color:#ededed;
    border:1px solid #ccc;
    border-bottom:none;
    border-radius:2px 2px 0px 0px;
    min-height:100px;
    padding:10px;

    display:flex;
    align-items:center;
    justify-content:space-evenly;

    .highreslogo {
      max-width: 200px;
      display: none;
    }

    .lowreslogo {
      width: 200px;
    }
  }

  .box {
      background-color: #fff;
      padding:25px;
      padding-top: 25px;
      border-radius:0px 0px 2px 2px;
      border:1px solid #ccc;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    span {
      color: #55a7f4;
      font-size: 10pt;
      border-bottom: 1px solid transparent;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid #55a7f4;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    h3 {
      text-align: center;
    }
  }

  .loading {
      background: transparent;
      font-size:0;
      width:30px;
      height:30px;
      border-radius:100%;
      padding:0;
      border:3px solid #ccc;
      border-bottom:3px solid rgba(255,255,255,0.0);
      border-left:3px solid rgba(255,255,255,0.0);
      animation-name: rotateAnimation;
      animation-duration: 0.5s;
      //animation-delay: 0.2s;
      animation-iteration-count: infinite;
      -webkit-animation-name: rotateAnimation;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-iteration-count: infinite;
  }

  @keyframes rotateAnimation {
      0%   {transform: rotate(0deg);}
      100% {transform: rotate(360deg);}
  }
  @-webkit-keyframes rotateAnimation {
      0%   {transform: rotate(0deg);}
      100% {transform: rotate(360deg);}
  }
}
