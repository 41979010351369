@import "colors";

.container {
  -webkit-box-shadow: 2px 2px 10px 0px rgba(97,97,97,0.7);
  -moz-box-shadow: 2px 2px 10px 0px rgba(97,97,97,0.7);
  box-shadow: 2px 2px 10px 0px rgba(97,97,97,0.7);
}

#login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background;

    animation: renderLogin 0.5s ease;
    -webkit-animation: renderLogin 0.5s ease;

    @keyframes renderLogin {
       0% {opacity: 0;}
       100% {opacity: 1;}
    }
    @-webkit-keyframes renderLogin {
       0% {opacity: 0;}
       100% {opacity: 1;}
    }

    .container {
        min-width: 300px;
        margin:5px;
        max-width: 500px;
        transition: min-width 0.3s ease;
        width: 100%;
    }

    .logo {
      background-color: $primary;
      border:1px solid #ccc;
      border-bottom:none;
      border-radius:2px 2px 0px 0px;
      min-height:150px;
      padding:10px;

      display:flex;
      align-items:center;
      justify-content:space-evenly;

      .highreslogo {
        max-width: 200px;
        display: none;
      }

      .lowreslogo {
        width: 200px;
      }
    }

    .box {
        background-color: #fff;
        padding:25px;
        padding-top: 15px;
        border-radius:0px 0px 2px 2px;
        border: 1px solid #ccc;

        h3 {
          text-align: center;
          padding-bottom: 10px;
        }
    }

    .text {
      width:100%;
      position: relative;

      input[type="text"], input[type="password"] {
        padding:15px;
        width:100%;
        border-radius:2px;
        border: 1px solid $sec1Fade;
        background-color: rgba(255,255,255,0);
        z-index: 1;

        &:focus {
          border: 1px solid $sec1;
          transition: border 0.5s ease;
        }
      }

      .placeh {
        cursor: text;
        padding: 15px;
        position: absolute;
        top: 0;
        left: 0;
        color: $sec1Fade;
        user-select: none;

        transition: top 0.3s ease;
        transition: left 0.3s ease;
        transition: background-color 0.5s ease;
      }

      .placeToLabel {
        position: absolute;
        top: -9px;
        left: 5px;
        font-size: 11pt;
        background-color: #fff;
        padding-left: 3px;
        padding-right: 3px;
        color: $sec1Fade;
        transition: top 0.3s ease;
        transition: left 0.3s ease;
        transition: background-color 0.5s ease;

        animation: renderLabel 0.5s ease;
        -webkit-animation: renderLabel 0.5s ease;

        @keyframes renderLabel {
         0% {
           top: 15px;
           left: 15px;
           color: $sec1Fade;
         }
         60% {
           top: -10px;
           left: 5px;
         }
         100% {
           color: $sec1;
         }
        }

        @-webkit-keyframes renderLabel {
         0% {
           top: 15px;
           left: 15px;
           color: $sec1Fade;
         }
         60% {
           top: -10px;
           left: 5px;
         }
         100% {
           color: $sec1;
         }
        }
      }

      .disabled {
        display: none;
      }
    }

    .text:first-of-type {
      margin-bottom:15px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      input[type="submit"] {
        display:none;
      }

      .submit {
        height: 50px;
        margin-top: 10px;
        cursor: pointer;
        background-color: $primary;
        border: 1px solid #ccc;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:10px;
        border-radius:2px;
        user-select: none;
        color: #fff;

        &:hover {
          background-color: $sec1;
          transition: background-color 0.5s;
        }
      }

      span {
        font-size: 11pt;
        color: #55a7f4;
        border-bottom: 1px solid transparent;
        transition: all 0.3s ease;

        &:hover {
          border-bottom: 1px solid #55a7f4;
          cursor: pointer;
        }
      }
    }

    .newUser {
      padding-top: 25px;
      font-size: 10pt;
      display: flex;
      justify-content: center;
      align-items: baseline;

      span {
        color: #55a7f4;
        border-bottom: 1px solid transparent;
        transition: all 0.3s ease;

        &:hover {
          border-bottom: 1px solid #55a7f4;
          cursor: pointer;
        }
      }
    }

    label {
      display: none;
    }

    .error-hide {
        opacity:0;

        //height:20px;
    }

    .error-show {
        background-color:#FFF7A7;
        border:1px solid #F2EB9E;
        padding:10px;
        text-align:center;
        opacity:1;
        font-size:16px;
        transition:opacity 0.5s;
        margin-top:5px;
        border-radius:2px;
    }

    .loading {
        background: transparent;
        font-size:0;
        width:30px;
        height:30px;
        border-radius:100%;
        padding:0;
        border:3px solid #fff;
        border-bottom:3px solid rgba(255,255,255,0.0);
        border-left:3px solid rgba(255,255,255,0.0);
        animation-name: rotateAnimation;
        animation-duration: 0.5s;
        //animation-delay: 0.2s;
        animation-iteration-count: infinite;
        -webkit-animation-name: rotateAnimation;
        -webkit-animation-duration: 0.5s;
        -webkit-animation-iteration-count: infinite;
    }

    @keyframes rotateAnimation {
        0%   {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    }
    @-webkit-keyframes rotateAnimation {
        0%   {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    }

}

#newuser {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: rendernewuser 0.5s ease;
  -webkit-animation: rendernewuser 0.5s ease;

  @keyframes rendernewuser {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  @-webkit-keyframes rendernewuser {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }

  .usercreated {
    display: none;
  }

  .createuser {
    display: block;
  }

  .container {
      min-width: 300px;
      width: 100%;
      margin:5px;
      max-width: 500px;
      transition: all 0.3s ease;
  }

  .logo {
    background-color: $primary;
    border:1px solid #ccc;
    border-bottom:none;
    border-radius:2px 2px 0px 0px;
    min-height:100px;
    padding:10px;

    display:flex;
    align-items:center;
    justify-content:space-evenly;

    .highreslogo {
      max-width: 200px;
      display: none;
    }

    .lowreslogo {
      width: 200px;
    }
  }

  .box {
      background-color: #fff;
      padding:25px;
      padding-top: 15px;
      border-radius:0px 0px 2px 2px;
      border:1px solid #ccc;

      h3 {
        text-align: center;
        padding-bottom: 10px;
      }
  }

  .text {
    width:100%;
    position: relative;
    margin-bottom: 15px;

    p {
      transition: all 0.5s ease;
    }

    input[type="text"], input[type="password"] {
      padding:15px;
      width:100%;
      border-radius:2px;
      border: 1px solid $sec1Fade;
      background-color: rgba(255,255,255,0);
      z-index: 1;

      &:focus {
        border: 1px solid $sec1;
        transition: border 0.5s ease;
      }
    }

    .placeh {
      cursor: text;
      padding: 15px;
      position: absolute;
      top: 0;
      left: 0;
      color: $sec1Fade;
      user-select: none;

      transition: top 0.3s ease;
      transition: left 0.3s ease;
      transition: background-color 0.5s ease;
    }

    .placeToLabel {
      position: absolute;
      top: -9px;
      left: 5px;
      font-size: 11pt;
      background-color: #fff;
      padding-left: 3px;
      padding-right: 3px;
      color: $sec1;
      transition: top 0.3s ease;
      transition: left 0.3s ease;
      transition: background-color 0.5s ease;

      animation: renderLabel 0.5s ease;
      -webkit-animation: renderLabel 0.5s ease;

      @keyframes renderLabel {
       0% {
         top: 15px;
         left: 15px;
         color: $sec1Fade;
       }
       60% {
         top: -10px;
         left: 5px;
       }
       100% {
         color: $sec1;
       }
      }

      @-webkit-keyframes renderLabel {
       0% {
         top: 15px;
         left: 15px;
         color: $sec1Fade;
       }
       60% {
         top: -10px;
         left: 5px;
       }
       100% {
         color: $sec1;
       }
      }
    }

    .disabled {
      display: none;
    }
  }

  .text:last-of-type {
    margin-bottom: 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-flow: row wrap;

    input[type="submit"] {
      display:none;
    }

    .submit {
      height: 50px;
      margin-top: 10px;
      cursor: pointer;
      background-color: $primary;
      border: 1px solid $sec1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border-radius:2px;
      user-select: none;
      color: #fff;

      &:hover {
        background-color: $sec1;
        transition: background-color 0.5s;
      }
    }

    span {
      color: #55a7f4;
      font-size: 10pt;
      border-bottom: 1px solid transparent;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid #55a7f4;
      }
    }
  }

  .error-hide {
    opacity:0;
  }

  .error-show {
    //background-color:#FFF7A7;
    color: red;
    // border: 1px solid #F2EB9E;
    padding: 5px;
    margin-bottom: 10px;
    text-align:center;
    opacity:1;
    font-size:11pt;
    transition:opacity 0.5s;
    margin-top:-15px;
    border-radius:2px;
  }

  label {
    display: none;
  }

  .loading {
      background: transparent;
      font-size:0;
      width:30px;
      height:30px;
      border-radius:100%;
      padding:0;
      border:3px solid #fff;
      border-bottom:3px solid rgba(255,255,255,0.0);
      border-left:3px solid rgba(255,255,255,0.0);
      animation-name: rotateAnimation;
      animation-duration: 0.5s;
      //animation-delay: 0.2s;
      animation-iteration-count: infinite;
      -webkit-animation-name: rotateAnimation;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-iteration-count: infinite;
  }

  .content {
    display: flex;
    justify-content: center;

    .loading {
      background: transparent;
      font-size:0;
      width:30px;
      height:30px;
      border-radius:100%;
      padding:0;
      border:3px solid #ccc;
      border-bottom:3px solid rgba(255,255,255,0.0);
      border-left:3px solid rgba(255,255,255,0.0);
      animation-name: rotateAnimation;
      animation-duration: 0.5s;
      //animation-delay: 0.2s;
      animation-iteration-count: infinite;
      -webkit-animation-name: rotateAnimation;
      -webkit-animation-duration: 0.5s;
      -webkit-animation-iteration-count: infinite;
    }
  }

  @keyframes rotateAnimation {
      0%   {transform: rotate(0deg);}
      100% {transform: rotate(360deg);}
  }
  @-webkit-keyframes rotateAnimation {
      0%   {transform: rotate(0deg);}
      100% {transform: rotate(360deg);}
  }
}
