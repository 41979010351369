@import 'colors';
#createwrap {
  form.yttre{
    margin-left: 20px;
    margin-right: 20px;
    max-width:100%;
  }

  .addTeamInfo {
    margin-left: 5px;
    color: gray;
    font-size: 11pt;
  }

  h2{
    font-size: 22pt;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
  }

  div#inre{
    border: 1px solid white;
    // border-radius: 2px;
    padding: 0;
  }

  div.form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }

  label {
    margin-bottom: 5px;
  }

  input:focus{
    border: 1px solid $sec1;
    transition: border 0.5s ease;
  }
  input:hover {
    border: 1px solid $sec1;
  }

  input.form {
    width: 100%;
    border-radius: 1px;
    outline: none;
  }

  textarea#tInfo{
    resize:none;
    min-height: 100px;
    //overflow:auto;
  }
  input,textarea{
    border: 1px solid $primary;
    border-radius: 2px;
    padding: 10px;
    max-width: 600px;
    width: 100%;
    outline:none;
  }

  div.lagholder{
    margin-bottom: 10px;
  }


  //Teams per group text
  input#perGroup{
    width: 50px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }
  .plussminus{
    width: 47px;
    border: 1px solid #afafaf;
    // background-color: $background-1;
    border-radius: 2.5px;
    display:flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  div#pizza{
    display:flex;
    align-items: center;

  }

  label#turInfo {
    max-width: 600px;
    font-size: 20px;
  //  padding-right: 10px;
    //padding-top: 5px;
    display:flex;
    align-items: center;
    justify-content: space-between;

  }
  div#valfritt{
    color:rgb(171,171,171);
    margin-left: 5px;
    white-space: nowrap;
    font-size: 15px;
  }
  label#asterisk{
    color:red;
  }
  div#counter{
    white-space: nowrap;
    color:rgb(171,171,171);
    font-size: 15px;
  }

  select {
    width: 50%;
  }

  div.teamwrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid $gold;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  div.newteam {
    padding-left: 4px;
    padding-right: 4px;
    //display: flex;
    font-size: 15px;
  }

  // -----------KNAPPAR ----------------
  //-------------------------------------
  #knappar{
    display:flex;
    padding-top: 10px;
  }

  div.removeteam {
    padding-left: 4px;
    margin: 2px;
    font-size: 21px;
    color:rgb(255,128,128);
    user-select: no
  }
  .removeteam:hover {
  //  background-color: rgba(255, 0, 0, 0.2);
    transition: background-color 0.25s ease;
    cursor: pointer;
    color:rgb(255,0,0);

  }
  .plussminus:hover{
    //box-shadow: 1px 1px 5px grey;
    background-color:#dedede;
    transition: background-color 0.5s;
    font-family: 'Montserrat', sans-serif;

    cursor:pointer;
  }

  #teams {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  div#nyttLag{
    //border: 2px solid black;
    display:flex;
  }
  span{
    color:red;
    font-size: 20px;
  }
  div#ast{
    margin-top: 8px;
    justify-content: center;
    font-size: 15px;
  }
  input.fält[type=text]{
    font-family: 'Montserrat', sans-serif;
    background-color: #fff;
  }

  input#plus[type=submit]{
    justify-content: center;
    display: flex;
    width: 80px;
    // background-color: $background-1;
    font-family: 'Montserrat', sans-serif;
  //  font-size: 10pt;
  }


  input#plus:hover {
  //  box-shadow: 1px 1px 5px grey;
    background-color:#dedede;
    transition: background-color 0.5s;
    cursor:pointer;
  }

    //Skapa ny turnering Knapp
  .submit:hover{
    cursor:pointer;
    background-color: $sec1;
    transition: background-color 0.5s;
  }

  .submit{
    height:50px;
    margin-top:10px;
    cursor:pointer;
    border:1px solid $sec1;
    max-width:600px;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:20px;
    border-radius:2px;
    user-select: none;
    background-color: $primary;
    color: #fff;
  }

  //----------Loading animation------------
  .loading {
      background: transparent;
      font-size:0;
      width:30px;
      height:30px;
      border-radius:100%;
      padding:0;
      border:3px solid #fff;
      border-bottom:3px solid rgba(255,255,255,0.0);
      border-left:3px solid rgba(255,255,255,0.0);
      animation-name: rotateAnimation;
      animation-duration: 0.5s;
      //animation-delay: 0.2s;
      animation-iteration-count: infinite;

  }

  .error-hide {
    opacity: 0;
  }

  .error-show {
    color: red;
    padding: 5px;
    padding-bottom: 0;
    opacity: 1;
    font-size: 12pt;
    transition: opacity 0.5s ease;
    border-radius: 2px;
  }

  .react-select-container {
    font-size: 12pt;
    width: 100%;

    .react-select__control {
      border-radius: 2px;

      .react-select__value-container {
        cursor: text;
      }
    }
  }
}

.modal {
    .window-btns {
        display:flex;
        justify-content: center;
        align-items: center;

        .remove {
            background-color:#ff3c00;
            border:1px solid #d90900;
            padding:20px 30px 20px 30px;
            color:#fff;
            border-radius:2px;
            width:fit-content;
            cursor:pointer;

            &:hover {
                background-color:#d90900;
                transition: background-color 0.5s;
            }
        }

        div {
            cursor:pointer;
            margin:10px;
        }

        div:first-of-type {
            border-radius:2px;
            padding:20px;
            border:1px solid #ccc;
            background-color:#ededed;
        }

        div:first-of-type:hover {
            background-color:#ccc;
            transition: background-color 0.5s;
        }
    }

    h3 {
        text-align:center;
        font-size:25px;
        padding:20px 0px 20px 0px;
    }

    #x {
        color: #000000;
        filter: alpha(opacity=20);
        width: 75%;
        font-size: 18px;
        margin-bottom: 10px;
    }

    input {
        padding:20px;
        border:1px solid $sec1;
        border-radius:2px 0px 0px 2px;
        background-color:#ededed;
        max-width:500px;
    }

    .copy {
      font-weight: bold;
      font-size: 18px;
      border-style: solid;
      border-color: $sec1;
      background-color: $primary;
      color: #fff;
      border-width: 1px 1px 1px 0px;
      border-radius: 0px 2px 2px 0px;
      padding: 0px 10px 0px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 20px;
      margin-bottom: 10px;

      &:hover {
        background-color: $sec1;
        transition: background-color 0.3s ease;
      }
    }

    .butn {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        background-color: $primary;
        border: 1px solid #ccc;
        padding: 15px;
        border-radius: 2px;
        margin-left: 10px;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.3s ease;
        color: #fff;
        font-size: 19px;

        &:hover {
          background-color: $sec1;
        }
      }
    }

    .modal_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;
        padding-bottom: 30px;
    }
}
