@import "colors";

.no-tournament {
  margin:auto;
  max-width:500px;
  width:100%;
  text-align:center;
  padding:20px;
  margin-top:50px;

  h1 {
      font-size:35px;
  }

  p {
      margin-top:10px;
      font-style:italic;
  }

  .link {
      margin-top:20px;
      margin-bottom:20px;
      font-size:30px;
      padding-bottom:60px;
  }

  .line {
      width:100%;
      max-width:100px;
      margin:20px;
      margin:auto;
      border-bottom:1px solid #ccc;

  }
}

#edit {
  animation: render2 0.3s ease;
  -webkit-animation: render2 0.3s ease;

  @keyframes render2 {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  @-webkit-keyframes render2 {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }

  margin:0px 20px 0px 20px;
  min-width: 270px;

  #root-loading {
    position:fixed;
    top:0;
    left:0;
    background-color: transparent;
    z-index:-999;
  }

  .loading_pos {
    position: fixed;
    top: 0;

    background-color: red;
  }

  .wrap {
    -webkit-box-shadow: 2px 2px 10px 0px rgba(97,97,97,0.7);
    -moz-box-shadow: 2px 2px 10px 0px rgba(97,97,97,0.7);
    box-shadow: 2px 2px 10px 0px rgba(97,97,97,0.7);
  }

  main {
    border: 1px solid $tan;
    border-top:none;
    border-radius:0px 0px 2px 2px;
    margin-bottom:150px;
    min-height:400px;

    .wrap {
      padding:20px;
    }

    h2 {
      font-size:25px;
      margin-right:20px;
    }
  }

  nav {
    z-index:1;
    background-color: #ededed;
    color: $dark;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius:2px 2px 0px 0px;
    display:flex;
    padding:0px 10px 0px 10px;
    margin-top:10px;

    div {
      width: calc(100% / 2);
      text-align: center;
      padding: 20px 0px 20px 0px;
      margin-right: 10px;
      border-bottom: 4px solid transparent;
      cursor: pointer;
      transition: border-bottom 0.3s ease;
      font-size: 20px;
    }

    div:nth-last-child(1) {
      margin-right:0;
    }

    div:not(.selected):hover {
      border-bottom:4px solid gray;
      color: black;
	  }

    .selected {
      border-bottom:4px solid $sec1;
      color: black;
    }
  }

  h1 {
    font-size:40px;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:10px 0px 10px 0px;

    span {
      color: #fff;
    }

    & > a {
      width: 40px;
      height: 40px;
      background-color: $primary;
      border-radius: 50%;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $sec1;
        span {
          border-color: #ededed;
          &:first-of-type {
            transform: rotate(-35deg);
            top: 15px;
            border-radius: 0 0 0 4px;
          }
          &:last-of-type {
            transform: rotate(35deg);
            bottom: 15px;
            border-radius: 4px 0 0 0;
          }
        }
      }

      div {
        position: relative;
        width: 100%;
        height: 100%;

        span {
          position: absolute;
          width: 15px;
          border-color: #ededed;
          border-style: solid;
          border-width: 0;
          transition: all 0.3s ease;


          &:first-of-type {
            border-bottom-width: 4px;
            transform: rotate(-45deg);
            top: 14px;
            left: 10.5px;
          }

          &:last-of-type {
            border-top-width: 4px;
            transform: rotate(45deg);
            bottom: 14px;
            left: 10.5px;
          }
        }
      }

      @media only screen and (max-width: 800px) {
        width: 50px;
        height: 50px;

        div {
          span {
            width: 20px;

            &:first-of-type {
              border-bottom-width: 7px;
              top: 17px;
              left: 14px;
            }

            &:last-of-type {
              border-top-width: 7px;
              bottom: 17px;
              left: 14px;
            }
          }
        }

        &:hover {
          span {
            &:first-of-type {
              top: 18.5px;
              border-radius: 0 0 0 7px;
            }
            &:last-of-type {
              bottom: 18.5px;
              border-radius: 7px 0 0 0;
            }
          }
        }
      }
    }


    .vafan {
      display: flex;

      div {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-flow: row wrap;
        background-color: $primary;
        transition: background-color 0.3s ease;
        padding: 10px;
        border-radius: 2px;
        cursor: pointer;

        div {
          position: relative;
          height: 29px;

          span {
            position: absolute;
            border-color: #fff;
            border-style: solid;
            border-width: 0;
            transition: background-color 0.3s ease;
            transition: border-color 0.3s ease;
            transition: bottom 0.3s ease;

            &:nth-child(1) {
              width: 20px;
              height: 17px;
              bottom: 0;
              border-width: 2px;
              border-top-width: 0;
            }
            &:nth-child(2) {
              width: 6px;
              border-bottom-width: 2px;
              bottom: 16px;
              right: 0;
            }
            &:nth-child(3) {
              width: 6px;
              border-bottom-width: 2px;
              bottom: 16px;
              left: 0;
            }
            &:nth-child(4) {
              height: 19px;
              border-left-width: 2px;
              bottom: 8px;
            }
            &:nth-child(5) {
              height: 9px;
              width: 9px;
              border-left-width: 2px;
              border-top-width: 2px;
              bottom: 17px;
              transform: rotate(45deg);
            }

            // &:nth-child(1) {
            //   width: 7px;
            //   height: 7px;
            //   border-radius: 50%;
            //   bottom: 0;
            //   right: 0;
            // }
            // &:nth-child(2) {
            //   width: 18px;
            //   border-bottom-width: 3px;
            //   transform: rotate(30deg);
            //   bottom: 6px;
            // }
            // &:nth-child(3) {
            //   width: 7px;
            //   height: 7px;
            //   border-radius: 50%;
            //   bottom: 8.5px;
            //   right: 14px;
            // }
            // &:nth-child(4) {
            //   width: 18px;
            //   border-bottom-width: 3px;
            //   transform: rotate(-30deg);
            //   bottom: 15px;
            // }
            // &:nth-child(5) {
            //   width: 7px;
            //   height: 7px;
            //   border-radius: 50%;
            //   bottom: 17px;
            //   right: 0;
            // }
          }
        }

        p {
          margin-left: 8px;
          padding: 0;
          font-weight: bold;
          color: #fff;
          transition: color 0.3s ease;
        }
      }

      &:hover {
        div {
          background-color: $sec1;

          div {
            span {
              border-color: #ededed;
            }
          }
        }

        p {
          color: #ededed;
        }
      }
    }
  }

  .head {
    h1 {
      margin: 0;
    }
  }
}

#settings {
  /*----RENDER----*/
  animation: render 0.3s ease;
  -webkit-animation: render 0.3s ease;
  background-color: #fff;

  @keyframes render {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  @-webkit-keyframes render {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  /*--------------*/

    input[type="text"], textarea {
        padding:15px;
        width:100%;
        border-radius:2px;
        border:1px solid $primary;
        margin-bottom:15px;
    }

    textarea {
        resize:vertical;
        padding:15px 0px 0px 15px;
        min-height:100px;
    }

    input:focus,textarea:focus{
        border: 1px solid $sec1;
        transition: border 0.5s ease;
    }

    input[type="text"] {
      letter-spacing: 0.1px;
      font-size: 15px;
    }

    .inputz {
        max-width:600px;
        margin-top:20px;
    }

    .teams {
        input {
            border-radius:0px 2px 2px 0px;
            margin:0;
            //max-width:400px;
        }

        & > div {
            display:flex;
            margin-bottom:10px;

            & > div {
                background-color: #ccc;
                white-space:nowrap;
                border-color: $primary;
                border-style:solid;
                border-width:1px 0px 1px 1px;
                padding:0px 10px 0px 10px;
                min-width: 70px;
                display:flex;
                justify-content:center;
                align-items:center;
                border-radius:2px 0px 0px 2px;
            }
        }
    }

    .head {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      align-items: center;

      h2 {
        margin: 0;
        font-size: 20pt;
      }

      .remove {
        background-color:#ff3c00;
        border:1px solid #d90900;
        padding:15px;
        color:#fff;
        border-radius:2px;
        width: fit-content;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row nowrap;
        transition: background-color 0.3s ease;

        .deleteIcon {
          width: 20px;
        }

        &::after {
          @media only screen and (min-width: 500px) {
            margin-left: 5px;
            content: "Ta bort";
            font-weight: bold;
          }
        }

        &:hover {
          background-color:#d90900;
        }
      }
    }

    input[type="submit"] {
        display:none;
    }

    .submit {
        height:50px;
        margin-top:10px;
        cursor:pointer;
        background-color: $primary;
        color: #fff;
        border:1px solid #ccc;
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        padding:10px;
        border-radius:2px;
        //margin-top:10px;
    }

    .submit:hover {
        background-color: $sec1;
        transition: background-color 0.5s;
    }

    .loading {
        background: transparent;
        font-size:0;
        width:30px;
        height:30px;
        border-radius:100%;
        padding:0;
        border:3px solid #fff;
        border-bottom:3px solid rgba(255,255,255,0.0);
        border-left:3px solid rgba(255,255,255,0.0);
        animation-name: rotateAnimation;
        animation-duration: 0.5s;
        //animation-delay: 0.2s;
        animation-iteration-count: infinite;
    }

    .error-hide {
        opacity:0;

        //height:20px;
    }

    .error-show {
        background-color:#FFF7A7;
        border:1px solid #F2EB9E;
        padding:10px;
        text-align:center;
        opacity:1;
        font-size:16px;
        transition:opacity 0.5s;
        border-radius:2px;
        //margin-top:-5px;
    }

    .ok {
        background-color: #5cb85c;
        border-color: #4cae4c;
        color:#fff;
    }

    .popup {
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:rgba(0, 0, 0, 0.5);
    }
}


.modal {
    .window-btns {
        display:flex;
        justify-content: center;
        align-items: center;

        .remove {
            background-color:#ff3c00;
            border:1px solid #d90900;
            padding:20px 30px 20px 30px;
            color:#fff;
            border-radius:2px;
            width:fit-content;
            cursor:pointer;

            &:hover {
                background-color:#d90900;
                transition: background-color 0.5s;
            }
        }

        div {
            cursor:pointer;
            margin:10px;
        }

        div:first-of-type {
            border-radius:2px;
            padding:20px;
            border:1px solid #ccc;
            background-color:#ededed;
        }

        div:first-of-type:hover {
            background-color:#ccc;
            transition: background-color 0.5s;
        }
    }

    h3 {
        text-align:center;
        font-size:25px;
        padding:20px 0px 20px 0px;
    }

    #x {
        color: #000000;
        filter: alpha(opacity=20);
        width: 75%;
        font-size: 18px;
        margin-bottom: 10px;
    }

    input {
        padding:20px;
        border:1px solid $sec1;
        border-radius:2px 0px 0px 2px;
        background-color:#ededed;
        max-width:500px;
    }

    .copy {
      font-weight: bold;
      font-size: 18px;
      border-style: solid;
      border-color: $sec1;
      background-color: $primary;
      color: #fff;
      border-width: 1px 1px 1px 0px;
      border-radius: 0px 2px 2px 0px;
      padding: 0px 10px 0px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 20px;
      margin-bottom: 10px;

      &:hover {
        background-color: $sec1;
        transition: background-color 0.3s ease;
      }
    }

    .butn {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        background-color: $primary;
        border: 1px solid #ccc;
        padding: 15px;
        border-radius: 2px;
        margin-left: 10px;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.3s ease;
        color: #fff;
        font-size: 19px;

        &:hover {
          background-color: $sec1;
        }
      }
    }

    .modal_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;
        padding-bottom: 30px;
    }
}
