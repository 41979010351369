@import "colors";

$line-color:#ccc;
$margin: 0px 3px 0px 3px; //fungerar ej..
$box-color: #ededed;
$box-padding:0px 0px 0px 0px;
$box-border:#ccc;

#bracket {
    $width:90%;
    $line-1:10%;
    
    display:flex;
    width:100%;
    min-width:1200px;
    //min-width:1500px;
    font-size:15px;

    

    & > div {
        height:calc(100vw / 1.5);
        max-height:900px; 
        min-height:600px;
    }

    .side {width:27%;}
    .top, .bottom {height:50%;}

    .half-1 {
        width:50%;
        height:100%;
    }

    .half-2 {
        width:100%;
        height:50%;
    }

    .f {
        display:flex;
    }

    .box-1{
        width: $width;
    }

    .box-2 {
        width: $width;
        height:100%;
    }

    .line-0 {
        width: $line-1;
        border-top:2px solid $line-color;
        border-right:4px solid $line-color;
    }

    .line-1 {
        width: $line-1;
        border-bottom:2px solid $line-color;
    }

    .line-2 {
        width: $line-1;
        border-bottom:2px solid $line-color;
        border-right:4px solid $line-color;
    }

    .line-3 {
        width: $line-1;
        border-top:2px solid $line-color;
    }

    .line-4 {
        width: $line-1;
        border-top:2px solid $line-color;
        border-left:4px solid $line-color;
    }

    .line-5 {
        width: $line-1;
        border-bottom:2px solid $line-color;
        border-left:4px solid $line-color;
    }

    .line-bottom {
        width: $line-1;
        height:100%;
        border-bottom:2px solid $line-color;
    }

    .line-top {
        width: $line-1;
        height:100%;
        border-top:2px solid $line-color;
    }

    .t1 {
        background-color: $box-color;
        margin:$margin;
        width:calc(100% - 6px);

        border-radius:0px 0px 2px 2px;
        border-width:0px 1px 1px 1px;
        border-style:solid;
        border-color:$box-border;
    }

    .t2 {
        background-color: $box-color;
        align-self: flex-end;
        width:calc(100% - 6px);
        margin: $margin;

        border-radius:2px 2px 0px 0px;
        border-width:1px 1px 0px 1px;
        border-style:solid;
        border-color:$box-border;
        border-bottom:1px solid #ccc;
    }

    .t3 {
        background-color: $box-color;
        align-self: flex-start;
        width:calc(100% - 6px);
        margin: $margin;

        border-radius:0px 0px 2px 2px;
        border-width:0px 1px 1px 1px;
        border-style:solid;
        border-color:$box-border;
    }  

    .lines {
        width:1.35%;
        .line-0, .line-1, .line-2, .line-3, .line-4, .line-5 {width:100%;}
    }

    .quarter {height:25%;}
    .semi {width:13.5%;}

    .final {
        width:16.3%;
        .team {
            padding:20px 10px 20px 10px;
        }
    } 
    
    .team {
        display:flex;
        align-items: center;
        justify-content: space-between;

        .team-name {
            overflow:hidden;
            margin:5px 0px 5px 5px;
        }

        .res {
            background-color:#fff;
            padding:10px 2px 10px 2px;
            margin:5px 5px 5px 0px;
            border-radius:2px;
            border:1px solid #ccc;
            min-width:40px;
            text-align: center;
        }
    }

    .empty {
        padding:17px 5px 17px 5px;
        font-style: italic;
    }

    .winner {
        background-color:rgba(240,230,140,0.4);
    }

    .loser {
        .team-name {
            text-decoration: line-through;
        }
    }


    @media screen and (max-width: 700px) {
        font-size:10px;
        min-width:700px;

        .t1, .t2, .t3 {
            padding:2px 2px 2px 2px;
        }
        
        .team {
            .res {
                padding:2px 2px 2px 2px;
                min-width:auto;
            }

            .final {
                padding:2px 2px 2px 2px;
            }
        }

        & > div {
            min-height:400px;
        }
    }
}