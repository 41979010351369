/* Global för hela sidan*/

$menuheight: 65px;

@import "colors";

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	background-color: $background;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
}

input, textarea, button {
	-webkit-appearance: none;
	outline:none;
	box-shadow: none !important;
}

textarea{
	font-family: 'Montserrat', sans-serif;
}

a {
	color:#000;
	text-decoration:none;
}

header {
	background-color: $primary;
	border-bottom:1px solid #ccc;
	width: 100%;
	height: 75px;
	@media screen and (max-width: 650px) {
		height: 110px;
	}
	z-index: 1000;

	.navis {
		margin: auto;
		max-width: 1200px;
		min-width: 300px;
		padding-right: 20px;
		padding-left: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;

		.logo {
			padding: 5px;

			.highreslogo {
				width: 120px;
				display: none;
			}

			.lowreslogo {
				width: 120px;
			}
		}

		nav {
			//width:100%;
			display:flex;
			//align-self:flex-end;
			margin-bottom:-1px;
			margin-left:20px;

			a {
				padding:10px 10px 5px 10px;
				display:block;
				border-bottom:4px solid transparent;
				margin:5px;
				//border-style:solid;
				//border-color:#ccc;
				//border-width:1px 0px 1px 1px;
				//text-decoration: underline;
				//text-decoration-color: $gold;

			}

			a:not(.selected):hover {
				border-bottom:4px solid #ccc;
			}

			.selected {
				border-bottom:4px solid $gold;
			}
		}

		.user {
			height: 100%;

			.name {
				width: 100%;
				height: 100%;
				padding: 0 30px 0 30px;
				user-select: none;
				flex-flow: row wrap;
				display: flex;
				align-items: center;

				p {
					font-size: 25px;
					padding: 0;
					color: #fff;
					margin-right: 10px;
					margin-left: 15px;
				}

				.pil {
					position: relative;
					width: 16px;
					height: 16px;

					span {
						width: 12px;
						height: 12px;
						position: absolute;
						border-color: #ededed;
						border-style: solid;
						border-width: 0;
						border-bottom-width: 3px;
						border-left-width: 3px;
						border-radius: 0 0 0 1px;
						top: 2px;
						left: 3px;
						transform: rotate(45deg);
						transition-property: transform left top;
						transition-timing-function: ease;
						transition-duration: 0.2s;
					}
				}

				&:hover {
					.pil {
						span {
							// border-color: #ccc;
						}
					}

					p {
						// color: #ccc;
					}
				}

				.accountIcon {
					position: relative;
					width: 16.5px;
					height: 22.5px;

					span {
						position: absolute;
						background-color: #fff;
						bottom: 0;

						&:nth-child(1) {
							width: 6px;
							height: 12px;
							border-radius: 85% 0 0 0;
						}
						&:nth-child(2) {
							width: 4px;
							height: 12px;
							left: 6px;
						}
						&:nth-child(3) {
							width: 6px;
							height: 12px;
							border-radius: 0 85% 0 0;
							left: 10px;
						}
						&:nth-child(4) {
							width: 9px;
							height: 9px;
							border-radius: 50%;
							bottom: 13.5px;
							left: 3.5px;
						}
					}
				}
			}

			#hamburger {
				position: relative;
				top: 0;
				z-index: 1030;

				div {
					padding: 1px;
					border-top: none;
					background-color: #557a95;
					border-radius: 0 0 4px 4px;
					position: absolute;
					right: 0;
					// left: -150%;
					top: 0;
					z-index: 1040;
					width: 250px;
					display: flex;
					flex-flow: column wrap;

					.settings {
						padding: 15px;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						position: static;
						flex-flow: row nowrap;
						cursor: pointer;

						div {
							position: relative;
							height: 25px;
							width: 25px;

							span {
								position: absolute;
								border-color: #ccc;
								border-style: solid;
								border-width: 0;
								left: 0;
								top: 0;

								&:nth-child(1) {
									height: 13px;
									width: 13px;
									border-width: 3px;
									border-radius: 50%;
									left: 5.5px;
									top: 6.25px;
									background-color: $sec1;
									z-index: 2000;
								}
								&:nth-child(2), &:nth-child(3),
								&:nth-child(4),&:nth-child(5) {
									width: 18px;
									height: 3.5px;
									border-width: 1.5px;
									background-color: #ccc;
									top: 11px;
									left: 3px;
									border-radius: 0.75px;
								}
								&:nth-child(3) {
									transform: rotate(90deg);
								}
								&:nth-child(4) {
									transform: rotate(45deg);
								}
								&:nth-child(5) {
									transform: rotate(-45deg);
								}
							}
						}

						a {
							white-space: nowrap;
							font-size: 20px;
							margin-left: 5px;
							color: #ccc;
							display: flex;
							justify-content: flex-start;
						}

						&:hover {
							div {
								span {
									border-color: #fff;

									&:nth-child(2), &:nth-child(3),
									&:nth-child(4),&:nth-child(5) {
										background-color: #fff;
									}
								}
							}
							a {
								color: #fff;
							}
						}
					}

					@media screen and (max-width: 650px) {
						width: 90vw;
						justify-content: center;

						.settings {
							justify-content: center;

							a {
								width: fit-content;
							}

							div {
								position: relative;
								height: 25px;
								width: 25px;
							}
						}
					}

					.logout {
						width: 100%;
						position: static;
						padding: 10px 0 10px 0;
						background-color: rgba(1,1,1,0.5);
						display: flex;
						justify-content: center;
						flex-flow: row wrap;

						span {
							margin: 15px;

							a {
								color: #ccc;
								font-size: 20px;

								&:hover {
									color: #fff;
								}
							}
						}
					}
				}
			}
		}

		.accountSelected {
			.name {
				background-color: $sec1;

				.accountIcon {
					span {
						background-color: #fff;
					}
				}

				p {
					color: #fff;
				}
				.pil {
					span {
						transition-property: transform left top;
						transition-timing-function: ease;
						transition-duration: 0.2s;
						transform: rotate(-45deg) !important;
						left: 2px !important;
						top: 0 !important;
						border-color: #fff;
					}
				}
			}
		}

		@media screen and (max-width: 650px) {
			flex-flow: row-reverse wrap;

			.user {
				height: 50%;

				.name {
					height: 100%;
					width: fit-content;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}

			a {
				width: 100%;
			}
			.logo {
				order: 0;
				height: 50%;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.highreslogo {
					width: 80px;
					display: none;
				}

				.lowreslogo {
					width: 80px;
				}
			}
		}
	}
}

h1, h2, h3, h4 {
	font-weight: normal;
}

.wrapper {
	width:100%;
	max-width:1200px;
	margin:auto;
	// border:2px dotted red;
}

.background {
	background-color: #fff;
	padding:10px;
}
