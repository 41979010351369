@import "colors";
$max-width: 1200px;

#manageSerie {
  /*----RENDER----*/
  animation: render 0.3s ease;
  -webkit-animation: render 0.3s ease;

  @keyframes render {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  @-webkit-keyframes render {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  /*--------------*/

  max-height: calc(100vh-50px-65px);

  .rcs-inner-handle {
    background-color: $sec-c1;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-top: 5px;
  }

  .rubrik {
    //position: fixed;
    //border: 1px solid lightgrey;
    // border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: $background-1;
    margin-top: 2px;
    width: 100%;
    overflow: hidden;
    height: 90px;
    position: relative;
    //transition: height 0.05s ease;

    .backbuttonContainer {
      //border: 1px solid black;
      float: left;
      color: gray;
      height: 50px;
      width: 7%;
      margin-top: 5px;

      transition: all 0.5s ease;

      &:hover {
        color: black;

        .backbutton {
          border-color: black;
          p {
            color: black;
          }
        }
      }

      .backbutton {
        cursor: pointer;
        user-select: none;
        color: gray;
        background: transparent;
        border: 1px solid gray;
        font-size: 18pt;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 26px;
        transition: all 0.5s ease;
        margin: auto;
        overflow: hidden;
      }

      p {
        text-align: center;
        user-select: none;
        transition: all 0.5s ease;
        width: 100%;
        overflow: hidden;
      }
    }

    .linkWrap {
      display: block;
      //border: 1px solid black;
      width: 100%;
      margin-top: 5px;
      height: 25px;
    }

    input[type=text] {
      float: left;
      background-color: #ededed;
      width: 8%;
      border: 1px solid gray;
      border-radius: 2px;
      height: 100%;
    }

    .copyButton {
      float: left;
      margin-left: 2px;
      width: 3%;
      border: 1px solid gray;
      border-radius: 2px;
      cursor: pointer;
      text-align: center;
      font-size: 25px;
      height: 100%;
      overflow: hidden;
      line-height: 20px;
    }

    .titleWrap {
      //border: 1px solid black;
      width: 93%;
      float: left;
      margin-top: 5px;
      height: 50px;
      overflow: hidden;
    }

    h2 {
      font-size: 28pt;
      //user-select: none;
      color: gray;
      text-align: center;
      margin-right: 4.5%;
    }
  }

  .groupDoneIndi {
    padding: 0 5px 0 5px;
    // color: #fff;
    // border: 1px solid #4cae4c;
    // background-color: #5cb85c;
    border-radius: 3px;
    text-align: center;
    position: absolute;
    right: 10px;
    top: 8px;

    @media screen and (max-width: 1100px) {
      right: 0;
    }

    @media screen and (max-width: 950px) {
      top: 30px;
    }
  }

  .grupper {
    position: relative;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 2px;
    padding: 10px;
    background-color: rgba(255,255,255,0.8);
    color: rgba(0,0,0,0.9);
    transition: all 0.3s ease;
    font-size: 12pt;
    cursor: pointer;
    font-weight: 500;
    max-width: 25%;
    user-select: none;
    margin: 2px;
    text-align: center;

    @media screen and (max-width: 950px) {
      height: 80px;
    }

    &:hover {
      //background-color: $background-1;
      border-color: $gold;
      box-shadow: none;
    }
  }

  .grupperSel {
    width: 100%;
    background-image: radial-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
    background-color: $gold;
    border: 1px solid $gold;
    border-radius: 2px;
    padding: 10px;
    transition: all 0.3s ease;
    font-size: 12pt;
    font-weight: 500;
    text-align: center;
    user-select: none;
    transform: scaleX(1);
    margin: 2px;
    max-width: 25%;

    @media screen and (max-width: 950px) {
      height: 80px;
    }
  }

  .editWrap {
    overflow: hidden;
    background-color: #fff;
    border-top: none;
    padding: 0.7%;
    padding-top: 10px;
    width: 100%;
    margin: auto;
    border-radius: 0 0 5px 5px;
    transition: all 0.5s ease;
  }

  .tabellwrap {
    margin: 0 10px 10px 10px;

    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  .tabellcontent {
    width: 100%;
  }

  .clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    height: 0;
    position: static;
  }

  .groupWrap {
    width: 100%;
    overflow: hidden;
    background-color: #ededed;
  }

  .groupContent {
    width: 100%;
    display: flex;
    padding: 12px 8px 0 8px;
  }

  .pointerWrap {
    // position: absolute;
    // left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.0);
  }

  .pointerContainer {
    position: relative;
    width: 100%;
    height: 6px;
    bottom: -8px;
    border-bottom: 1px solid #ccc;
  }

  .pointer-a {
    animation-name: pointerAnim1;
    animation-duration: 0.5s;
    -webkit-animation-name: pointerAnim1;
    -webkit-animation-duration: 0.5s;

    @keyframes pointerAnim1 {
      0%    {
        opacity: 0;
      }
      75% {
        transform: translate(-4px,5px) rotate(0deg);
        opacity: 0;
      }
      85% {
        opacity: 1;
      }
      100% {
        transform: translate(0,0) rotate(-25deg);
      }
    }
    @-webkit-keyframes pointerAnim1 {
      0%    {
        opacity: 0;
      }
      75% {
        transform: translate(-4px,5px) rotate(0deg);
        opacity: 0;
      }
      85% {
        opacity: 1;
      }
      100% {
        transform: translate(0,0) rotate(-25deg);
      }
    }

    border: none;
    position: absolute;
    width: 25px;
    //border-top: 1px solid #ccc;
    height: 15px;
    background-color: #fff;

    transition: left 0.2s ease;

    transform: rotate(-25deg);
  }

  .pointer-b {
    border: none;
    position: absolute;
    width: 25px;
    margin-left: 17px;
    height: 15px;
    //border-top: 1px solid #ccc;
    background-color: #fff;

    transition: left 0.2s ease;

    animation-name: pointerAnim2;
    animation-duration: 0.5s;
    -webkit-animation-name: pointerAnim2;
    -webkit-animation-duration: 0.5s;

    @keyframes pointerAnim2 {
        0%    {
          opacity: 0;
        }
        75% {
          transform: translate(-4px,5px) rotate(0deg);
          opacity: 0;
        }
        85% {
          opacity: 1;
        }
        100% {
          transform: translate(0,0) rotate(25deg);
        }
    }
    @-webkit-keyframes pointerAnim2 {
        0%    {
          opacity: 0;
        }
        75% {
          transform: translate(-4px,5px) rotate(0deg);
          opacity: 0;
        }
        85% {
          opacity: 1;
        }
        100% {
          transform: translate(0,0) rotate(25deg);
        }
    }

    transform: rotate(25deg);
  }

  .resultatwrap-loading {
    margin-top: 50%;
    margin-left: 50%;
    float: left;
    width:50px;
    height:50px;
    border-radius:100%;
    padding:0;
    border:5px solid #fff;
    border-top:5px solid rgba(255,255,255,0.0);
    border-left:5px solid rgba(255,255,255,0.0);

    animation: rotateAnimation 2s ease infinite;
    -webkit-animation: rotateAnimation 2s ease infinite;

    @keyframes rotateAnimation {
        0%   {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
    }
    @-webkit-keyframes rotateAnimation {
        0%   {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
    }
  }

  .lds-ring {
    margin-top: 200px;
    margin-left: 30%;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .lds-ring div {
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: 0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: 0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: 0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading {
    background: transparent;
    font-size:0;
    width:30px;
    height:30px;
    border-radius:100%;
    padding:0;
    border:3px solid #fff;
    border-bottom:3px solid rgba(255,255,255,0.0);
    border-left:3px solid rgba(255,255,255,0.0);
    animation-name: rotateAnimationPageOpen;
    animation-duration: 0.5s;
    //animation-delay: 0.2s;
    animation-iteration-count: infinite;
  }

  .loadingrubrik {
    background-color: $background-1;
    color: $background-1;
    width: 5%;
  }
}

.loadinganim {
  display: flex;
}

#futureGamesWrap {
  /*----RENDER----*/
  animation: render2 0.3s ease;
  -webkit-animation: render2 0.3s ease;

  @keyframes render2 {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  @-webkit-keyframes render2 {
     0% {opacity: 0;}
     100% {opacity: 1;}
  }
  /*--------------*/
  // height: 100vh;
  //overflow: hidden;

  .futureGamesContent {
    border-bottom: 2px solid rgba(0,0,0,0.4);
    padding-bottom: 10px;

    @media only screen and (max-width: 800px) {
      border-bottom: none;
    }

    h3 {
      padding-top: 10px;
      text-align: center;
      font-size: 20pt;
    }
  }

  .handleGroupBtns {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;

    @media only screen and (min-width: 800px) {
      .addMatchBtn {
        cursor: pointer;
        background-color: $primary;
        border: 1px solid $sec1;
        transition: all 0.3s ease;
        border-radius: 2px;
        position: relative;
        padding: 15px;
        font-size: 15px;
        color: #fff;
        font-weight: bold;

        &:hover {
          background-color: $sec1;
        }
      }

      .addMatchBtn::before {
        content: "Ny match";
      }
    }

    @media only screen and (max-width: 800px) {
      .addMatchBtn {
        cursor: pointer;
        background-color: $primary;
        border: 1px solid $sec1;
        transition: all 0.3s ease;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
      }

      .addMatchBtn:hover {
        background-color: $sec1;

        &::after {
          background-color: #fff;
        }
        &::before {
          background-color: #fff;
        }
      }

      .addMatchBtn::after {
        content: " ";
        position: absolute;
        display: block;
        background-color: #ededed;
        height: 3px;
        top: calc(50% - 2px);
        left: 6px;
        right: 6px;
        z-index: 9;
        transition: all 0.3s ease;
      }

      .addMatchBtn::before {
        content: " ";
        position: absolute;
        display: block;
        background-color: #ededed;
        width: 3px;
        left: calc(50% - 1.5px);
        top: 6px;
        bottom: 6px;
        z-index: 9;
        transition: all 0.3s ease;
      }
    }
  }

  .removeMatchBtn {
    width: 100%;
    height: 0;
    left: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      position: absolute;
      left: 0;
      top: -25px;
      cursor: pointer;
      background-color: #ff3c00;
      border: 1px solid #d90900;
      transition: all 0.3s ease;
      border-radius: 2px;
      position: relative;
      padding: 15px;
      font-size: 15px;
      color: #fff;
      font-weight: bold;

      &::before {
        content: "ta bort match";
      }

      &:hover {
        background-color: #d90900;
      }
    }
  }

  h3 {
    text-align: center;
    font-size: 20pt;
    margin-bottom: 10px;

    .rubrComment {
      color: gray;
      font-size: 10pt;
      transition: all 0.3s ease;
    }

    .rubrCommentRed {
      color: #d90900;
      font-size: 10pt;
      transition: all 0.3s ease;
    }
  }

  h3:last-of-type {
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }

  .tabellTitel {
    font-size: 20pt;
    margin-top: 10px;
  }

  .game {
    padding-top: 10px;
    padding-bottom: 10px;

    form {
      all: none;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
    }

    .mark {
      width: 100%;
      top: -18px;
      left: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div {
        left: 0;
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;

        div {
          position: relative;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border: none;
          transform: rotate(45deg);
          // border: 1px solid $sec1Fade;

          .removingLoading {
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;

            .removingAnim {
              margin: auto;
              background: transparent;
              font-size:0;
              width: 20px;
              height: 20px;
              border-radius:100%;
              padding:0;
              border: 3px solid transparent;
              border-bottom: 4px solid #ff3c00;
              border-left: 4px solid #ff3c00;
              animation-name: rotateAnimation;
              animation-duration: 0.5s;
              animation-iteration-count: infinite;
            }
          }

          span {
            position: absolute;
            border-color: $sec1Fade;
            background-color: #ededed;
            width: 9px;
            height: 4px;
            border-style: solid;
            border-width: 0;
            border-width: 1px;
            border-right-width: 0;
            transition: border-color 0.3s ease;

            &:nth-child(1) {
              top: 9px;
              left: 0;
            }
            &:nth-child(2) {
              transform: rotate(90deg);
              top: 3px;
              left: 6px;
            }
            &:nth-child(3) {
              transform: rotate(180deg);
              left: 12px;
              top: 9px;
            }
            &:nth-child(4) {
              transform: rotate(270deg);
              left: 6px;
              top: 15px;
            }
            &:nth-child(5) {
              width: 3.5px;
              height: 3.5px;
              left: 9px;
              top: 9.5px;
              border-right-width: 1px;
              border-color: #ededed;
            }
          }
        }

        &:hover {
          div {
            span {
              border-color: #d90900;

              &:nth-child(5) {
                border-color: #ededed;
              }
            }
          }
        }
      }
    }

    .team {
      width:100%;
      margin-top: 10px;
      &:first-of-type {text-align:right;}
    }

    .errMessage {
      width: 150px;
      position: absolute;
      right: 2%;
      margin: auto;
      margin-top: 10px;
      text-align: center;
      font-size: 17px;

      .cannotEdit {
        color: red;
        font-size: 11pt;
        //border: 1px solid red;
      }
    }

    .buttons {
      width: 100%;
      top: -18px;
      left: 0;
      position: relative;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .cannotEditDis {
        display: none;
      }

      input[type="submit"] {
        display:none;
      }

      .okButton {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        width: 100px;
        height: 35px;
        border: 1px solid $sec1;
        border-radius: 3px;
        background-color: $primary;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #fff;

        &:hover {
          background-color: $sec1;
        }

        animation: okButtonRender 0.5s ease;
        -webkit-animation: okButtonRender 0.5s ease;

        @keyframes okButtonRender {
           0% {
             opacity: 0;
             transform: translateX(50px);
           }
           50% {
             opacity: 0;
           }
           100% {
             transform: translateX(0);
             opacity: 1;
           }
        }
        @-webkit-keyframes okButtonRender {
           0% {
             opacity: 0;
             transform: translateX(50px);
           }
           50% {
             opacity: 0;
           }
           100% {
             transform: translateX(0);
             opacity: 1;
           }
        }
      }

      .okButton::after {
        content: "OK";
      }

      .okButtonSparat {
        position: absolute;
        width: 100px;
        height: 35px;
        border: 1px solid #4cae4c;
        border-radius: 3px;
        padding: 4px 9px 4px 9px;
        background-color: #5cb85c;
        color: white;
        transition-property: border background-color color;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
      }

      .okButtonError {
        position: absolute;
        width: 100px;
        height: 35px;
        background-color: #ff3c00;
        border: 1px solid #d90900;
        border-radius: 3px;
        padding: 4px 9px 4px 9px;
        color: white;
        transition-property: border background-color color;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
      }

      .okButtonSparat::after {
        content: "Sparad!"
      }

      .okButtonError::after {
        content: "Fel!"
      }

      .okButtonHidden {
        position: absolute;
        opacity: 0;
        right: 0;
        width: 100px;
        height: 35px;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 4px 9px 4px 9px;
        background-color: #ededed;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .okButtonHiddenAfterFail {
        opacity: 0;
        position: absolute;
        width: 100px;
        height: 35px;
        border: 2px solid lightgray;
        border-radius: 3px;
        padding: 4px 9px 4px 9px;
        background-color: #ededed;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;

        animation: failFadeOut 0.5s ease;
        -webkit-animation: failFadeOut 0.5s ease;

        @keyframes failFadeOut {
          0% {
            transform: translateX(0);
            opacity: 1;
            right: 0;
          }
           100% {
             opacity: 0;
             transform: translateX(50px);
           }
        }
        @-webkit-keyframes failFadeOut {
          0% {
            transform: translateX(0);
            opacity: 1;
            right: 0;
          }
           100% {
             opacity: 0;
             transform: translateX(50px);
           }
        }
      }

      .okButtonHiddenAfterSave::after {
        content: "Sparad!"
      }

      .okButtonHiddenAfterError::after {
        content: "Fel!"
      }

      .okButtonHiddenAfterFail::after {
        content: "OK"
      }

      .okButtonHiddenAfterSave {
        opacity: 0;
        position: absolute;
        width: 100px;
        height: 35px;
        border: 1px solid #4cae4c;
        border-radius: 3px;
        padding: 4px 9px 4px 9px;
        background-color: #5cb85c;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;

        animation: saveFadeOut 0.5s ease;
        -webkit-animation: saveFadeOut 0.5s ease;

        @keyframes saveFadeOut {
          0% {
            transform: translateX(0);
            opacity: 1;
            right: 0;
          }
           100% {
             opacity: 0;
             transform: translateX(50px);
           }
        }
        @-webkit-keyframes saveFadeOut {
          0% {
            transform: translateX(0);
            opacity: 1;
            right: 0;
          }
           100% {
             opacity: 0;
             transform: translateX(50px);
           }
        }
      }

      .okButtonHiddenAfterError {
        opacity: 0;
        position: absolute;
        width: 100px;
        height: 35px;
        background-color: #ff3c00;
        border: 1px solid #d90900;
        border-radius: 3px;
        padding: 4px 9px 4px 9px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;

        animation: errorFadeOut 0.5s ease;
        -webkit-animation: errorFadeOut 0.5s ease;

        @keyframes errorFadeOut {
          0% {
            transform: translateX(0);
            opacity: 1;
          }
           100% {
             opacity: 0;
             transform: translateX(50px);
           }
        }
        @-webkit-keyframes errorFadeOut {
          0% {
            transform: translateX(0);
            opacity: 1;
          }
           100% {
             opacity: 0;
             transform: translateX(50px);
           }
        }
      }

      .okButtonLoading {
        position: absolute;
        width: 100px;
        height: 35px;
        border: 2px solid $sec1;
        border-radius: 3px;
        padding: 4px 9px 4px 9px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
      }
    }

    .res {
      // height: 35px;
      margin: 0px 10px 0px 10px;
      max-width: fit-content;
      // white-space: nowrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $primary;
      border-radius:2px;
      background-color:#fff;
      transition: all 0.5s ease;

      .betweenLine {
        border-bottom: 1px solid black;
        width: 4px;
      }

      .textFieldDisabled {
        font-size: 10pt;
        padding: 10px;
        border: none;
        width: 40px;
        text-align: center;
        background-position: rgba(0,0,0,0);
      }

      input[type=text] {
        font-family: 'Montserrat', sans-serif;
        padding: 10px;
        border: none;
        width: 40px;
        text-align: center;
        background-color: transparent;
      }

      input[type="text"]:disabled {
        background: inherit;
        color: gray;
      }
    }

    .disabled {
      background-color: #ededed;
      border-color: #ccc;
    }

    .resInvisible {
      display: none;
    }

    .resClosed {
      background-color: #ededed;
      color: gray;
      transition: all 0.3s ease;
    }

    .resCloseIndication {
      color: gray;
      background-color: #ededed;
      border-color: #d90900;
      transition: all 0.3s ease;
    }

    @media only screen and (max-width: 800px) {
      border-bottom: 1px solid #ededed;
      padding-top: 30px;
      padding-bottom: 30px;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }

      .buttons {
        position: relative;
        top: -105px;
        width: 100%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .okButton {
          width: 80px;
          height: 70px;
        }

        .okButtonSparat {
          width: 80px;
          height: 70px;
        }

        .okButtonError {
          width: 80px;
          height: 70px;
        }

        .okButtonHidden {
          width: 80px;
          height: 70px;
        }

        .okButtonHiddenAfterFail {
          width: 80px;
          height: 70px;
        }

        .okButtonHiddenAfterSave {
          width: 80px;
          height: 70px;
        }

        .okButtonHiddenAfterError {
          width: 80px;
          height: 70px;
        }

        .okButtonLoading {
          position: absolute;
          width: 80px;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0;
        }
      }

      form {
        position: relative;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;
        padding-right: 20px;
      }

      .res {
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
        max-width: none;
        flex-flow: column;

        input[type=text] {
          width: 100%;
          padding: 15px;
        }

        .betweenLine {
          width: 90%;
          border-color: #ccc;
        }
      }
    }
  }

  .buttonLoadingAnim {
    margin: auto;
    background: transparent;
    font-size:0;
    width: 20px;
    height: 20px;
    border-radius:100%;
    padding:0;
    border:3px solid #fff;
    border-bottom:3px solid rgba(255,255,255,0.0);
    border-left:3px solid rgba(255,255,255,0.0);
    animation-name: rotateAnimation;
    animation-duration: 0.5s;
    //animation-delay: 0.2s;
    animation-iteration-count: infinite;
  }

  .react-select-container {
    font-size: 12pt;
    width: 25%;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }

    .react-select__control {
      border-radius: 2px;

      .react-select__value-container {
        cursor: text;

        @media only screen and (max-width: 800px) {
          padding: 10px;
        }
      }

      .react-select__indicators {
        cursor: pointer;
      }
    }

    .react-select__menu {
      .react-select__menu-list {

        .react-select__option {
          cursor: pointer;
        }
      }
    }
  }
}
