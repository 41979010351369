#games {
    border:1px solid #ccc;
    border-radius:2px;
    
    //min-width:fit-content;

    .item {
        //padding:10px;
        display:flex;
        //align-items:center;
        border-bottom:1px solid #ccc;
        
        &:last-child {border:none;}
        &:nth-child(2n){
            background-color:#ededed;
        }

        .left {
            width:150px;
            background-color:#ededed;
            border-right:1px solid #ccc;
            display:flex;
            align-items:center;

            .n {
                padding:0 10px 0px 10px;
                font-style:italic;
                white-space:nowrap;
            }

            .date {
                //color:#fff;
                width:100%;
                margin-right:10px;
                text-align:center;
            }
        }

        .right {
            width:calc(100% - 150px);
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:10px;

            .team {
                width:100%;

                &:first-of-type {text-align:right;}
            }

            .res {
                margin:0px 10px 0px 10px;
                padding:10px;
                width:150px;
                white-space: nowrap;
                //width:fit-content;
                border:1px solid #ccc;
                border-radius:2px;
                background-color:#fff;
                text-align:center;
            }

        }
    }

    @media screen and (max-width: 650px) {
        .item {
            //padding:30px;
            //background-color: red;
            flex-direction:column-reverse;

            .right {
                width:100%;
            }

            .left {
                width:auto;
                border:1px solid #ccc;
                background-color:#fff;
                padding:5px;
                margin-bottom:10px;
                border-radius:2px;
                //background-color:transparent;
                //border:none;
                align-self:center;
                //width:100%;

                .n {
                    padding:0;
                }

                .date {
                    //background-color:red;
                    margin:0px 0px 0px 20px;
                    min-width:50px;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .item {
            .right {
                flex-direction: column;
                justify-content:flex-start;

                .team {
                    &:last-of-type {text-align:center; padding-top:10px;}
                    &:first-of-type {text-align:center; padding-bottom:10px;}
                }

                .res {
                    width:fit-content;
                    padding-left:20px;
                    padding-right:20px;
                }
            }
            
        }
    }
}