/* IMPORT till fil där de behövs
@import 'colors'; */

/*** FÄRGPALETTE  ***/
$primary-c: #226666;
/* Secondaries, Ljus --> Mörkt */
$sec-c1: #669999;
$sec-c2: #407F7F;
$sec-c3: #0D4D4D;
$sec-c4: #003333;

/*** FÄRGPALETT  ***/
$primary: #7395ae;
/* Secondaries, Ljus --> Mörkt */
$sec1: #557a95;
$sec1Fade: rgba(85, 122, 149, 0.4);
$tan: #b1a296;
$light: #379683;
$dark: #5d5c61;

$gold: #d1af47;


$background: #f6f6f6;
$background-1: #f6f6f6;
